import { message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import axios from 'axios';
import short from 'short-uuid';
import { Post } from '../../utilities/useApi';

export const AmenityOptions = [
  {
    'value': 'Water Supply',
    'title': 'Water Supply',
  },
  {
    'value': 'Power Supply',
    'title': 'Power Supply',
  },
  {
    'value': 'Backup Power',
    'title': 'Backup Power',
  },
  {
    'value': 'Air Conditioning',
    'title': 'Air Conditioning',
  },
  {
    'value': 'Heater',
    'title': 'Heater',
  },
  {
    'value': 'Elevator',
    'title': 'Elevator',
  },
  {
    'value': 'Balcony',
    'title': 'Balcony',
  },
  {
    'value': 'Security',
    'title': 'Security',
  },
  {
    'value': 'Packing Space',
    'title': 'Packing Space',
  },
  {
    'value': 'WiFi',
    'title': 'WiFi',
  },
  {
    'value': 'Pool',
    'title': 'Pool',
  },
  {
    'value': 'Laundry Service',
    'title': 'Laundry Service',
  },
  {
    'value': 'Fridge',
    'title': 'Fridge',
  },
  {
    'value': 'HD TV',
    'title': 'HD TV',
  },
  {
    'value': 'Hairdryer',
    'title': 'Hairdryer',
  },
  {
    'value': 'Iron',
    'title': 'Iron',
  },
  {
    'value': 'King-size beds',
    'title': 'King-size beds',
  },
  {
    'value': 'Queen-size beds',
    'title': 'Queen-size beds',
  },
  {
    'value': 'Standard beds',
    'title': 'Standard beds',
  },
];

export const beforeUpload = (file: { size: number }, max = 20) => {
  const isLt2M = file.size / 1024 / 1024 <= max;
  if (!isLt2M) {
    message.error(`File size can not be more than ${max}MB!`);
  }
  return isLt2M;
};

export const dummyRequest = (req: any) => {
  setTimeout(() => {
    req.onSuccess('ok');
  }, 0);
};

export const handleMediaChange = async (info: UploadChangeParam, props: any, field: string, token: string | null, multiple: boolean) => {

  if (info.file.status === 'done') {
    message.success(`${info.file.name} file uploaded successfully`);
    info.fileList.map((file) => {
      Post({
        url: '/utility/get-signed-url',
        data: {
          name: `${short().new()}${Date.now()}`,
          type: file.type,
        },
        token,
      })
        .then((res) => {
          const value = multiple ? [...props.values[field], {
            url: res.data.url,
            type: file.type as string,
          }] : res.data.url;
          props.setFieldValue(field, value);
          axios.put(`${res.data.signedRequest}`, file.originFileObj);
        })
        .catch((error) => error);
    });
  } else if (info.file.status === 'error') {
    message.error(`${info.file.name} file upload failed.`);
  }
};

export const handleDocChange = async (info: UploadChangeParam, props: any, field: string, token: string | null, multiple: boolean) => {
  let newFileList = [...info.fileList];
  props.setFieldValue('fmmDoc', newFileList);

  if (info.file.status === 'done') {
    message.success(`${info.file.name} file uploaded successfully`);
    info.fileList.map((file) => {
      axios.post('https://api.filmmakersmart.com/utility/get-signed-url', {
        name: `${short().new()}${Date.now()}`,
        type: file.type,
      })
        .then((res) => {
          const value = multiple ? [...props.values[field], {
            url: res.data.data.data.url,
            type: file.type as string,
          }] : res.data.data.data.url;
          props.setFieldValue(field, value);
          axios.put(`${res.data.data.data.signedRequest}`, file.originFileObj);
        })
        .catch((error) => error && message.error(`${info.file.name} file upload failed.`));
    });
  } else if (info.file.status === 'error') {
    message.error(`${info.file.name} file upload failed.`);
  }
};