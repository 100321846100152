import { Button, DatePicker, Input, Radio, Select, TimePicker, Upload } from 'antd';
import { beforeUpload, dummyRequest, handleDocChange, handleMediaChange } from './helper';
import { BsCameraFill, BsPaperclip } from 'react-icons/bs';
import { IPCountrySelect, IPDate, IPInput, IPSelect } from './interfaces';
import { countries } from '../../utilities/dataHelper/country';
import dayjs from 'dayjs';
import { FlexibleDiv } from '../box';
import { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { FmmFormStyles } from './style';
import { FmmButton } from '../button';
import { FmmTheme, ITheme } from '../theme';
import { Image } from 'antd';
import NumberFormat from 'react-number-format';


interface IMedia {
  label: string;
  props: any;
  field: string;
  token: string | null;
  objectTitle?: string,
  objectProperty?: string,
  nested?: boolean,
}

export const UploadMedia = ({ label, props, field, token, nested = false, objectTitle = '', objectProperty = '' }: IMedia) => (
  <div className='TextAreaForm'>
    <label>{label}</label>
    <div className='selects'>
      <Upload
        customRequest={dummyRequest}
        onChange={(info) => handleMediaChange(info, props, field, token, true)}
        multiple
        accept='.png, .jpg, .jpeg, .mov, .mp4, .avi'
        beforeUpload={(file) => beforeUpload(file, 50)}
      >
        <Button>
          <BsCameraFill className='icon' /> {''} {''} Upload Media
        </Button>
      </Upload>
    </div>
    <label>Jpeg, Mp4 etc</label>
    <br />
    {props.touched[field] && props.errors[field] && (
      <small className='field-error'>{props.errors[field]}</small>
    )}
    {nested && props.touched[objectTitle] && props.touched[objectTitle][objectProperty] &&
      props.errors[objectTitle] && props.errors[objectTitle][objectProperty] &&
      (<small className='field-error'> {props.errors[objectTitle][objectProperty]} </small>)
    }
  </div>
);

export const UploadDoc = ({ label, props, field, token, nested = false, objectTitle = '', objectProperty = '' }: IMedia) => (
  <div className='AddLocationFormContainer'>
    <label>{label}</label>
    <div className='selects'>
      <Upload
        onChange={(info) => handleDocChange(info, props, field, token, false)}
        customRequest={dummyRequest}
        accept='.doc, .docx, .pdf'
        beforeUpload={(file) => beforeUpload(file, 20)}
      >
        <Button>
          <BsPaperclip className='icon' /> {''} {''} Attach File
        </Button>
      </Upload>
    </div>
    <label>DOC, PDF etc...</label>
    <br />
    {props.touched[field] && props.errors[field] && (
      <small className='field-error'>{props.errors[field]}</small>
    )}
    {nested && props.touched[objectTitle] && props.touched[objectTitle][objectProperty] &&
      props.errors[objectTitle] && props.errors[objectTitle][objectProperty] && (<small className='field-error'>{props.errors[objectTitle][objectProperty]}</small>)}
  </div>
);

export const PInput = ({
  label,
  type = 'text',
  placeholder = 'Enter Response',
  props,
  value,
  field,
  disabled,
  nested = false,
  objectTitle = '',
  objectProperty = '',
  amount = false,
}: IPInput) => {
  return (
    <>
      <label className='p'>{label}</label>
      {
        amount ? (
          <NumberFormat
              thousandSeparator=","
              decimalSeparator="."
              // prefix="₦ "
              onChange={props?.handleChange(field)}
              onBlur={props?.handleBlur(field)}
              value={props?.values[field ?? '']}
              placeholder={placeholder}
              onFocus={() => {
                if (type === 'number' && value === 0) props?.setFieldValue(field, '');
              }}
              className='fmmInput amount'
              id='fmmInput'
            />
        ) :
        <Input
          disabled={disabled}
          type={type}
          placeholder={placeholder}
          onBlur={props.handleBlur(field)}
          onChange={props.handleChange(field)}
          value={props.values[field]}
          onFocus={() => {
            if (type === 'number' && value === 0) props.setFieldValue(field, '');
          }}
        />
      }

      {nested && props.touched[objectTitle] && props.touched[objectTitle][objectProperty] &&
        props.errors[objectTitle] && props.errors[objectTitle][objectProperty] && (<small className='field-error'>{props.errors[objectTitle][objectProperty]}</small>)}
      {props.touched[field] && props.errors[field] && (
        <small className='field-error'>{props.errors[field]}</small>
      )}
    </>
  );
};

export const PDate = ({
  label,
  placeholder = 'Enter Response',
  props,
  field,
  disabledDate,
  defaultValue,
}: IPDate) => (
  <>
    <label className='p'>{label}</label>
    <DatePicker
      disabledDate={disabledDate}
      placeholder={placeholder}
      onBlur={props.handleBlur(field)}
      // eslint-disable-next-line @typescript-eslint/naming-convention
      onChange={(_date, dateString) => {
        props.setFieldValue(field, dateString);
        props.handleChange(field);
      }}
      defaultValue={dayjs(defaultValue || Date.now())}
    />
    {props.touched[field] && props.errors[field] && (
      <small className='field-error'>{props.errors[field]}</small>
    )}
  </>
);

export const PTime = ({
  format,
  label,
  placeholder = 'Enter Response',
  props,
  defaultValue,
  field,
  disabledDate,
  use12Hours,
}: IPDate) => (
  <>
    <label className='p'>{label}</label>
    <TimePicker
      use12Hours={use12Hours}
      disabledTime={disabledDate}
      placeholder={placeholder}
      onBlur={props.handleBlur(field)}
      onChange={(time, timeString) => {
        props.setFieldValue(field, timeString);
        props.handleChange(field);
      }} defaultValue={dayjs(defaultValue || Date.now())}
      format={format}

    />
    {props.touched[field] && props.errors[field] && (
      <small className='field-error'>{props.errors[field]}</small>
    )}
  </>
);

export const PTextArea = ({ label, placeholder, field, props, paddingTop = '1rem' }: IPInput) => {
  const { TextArea } = Input;
  return (
    <div className='' style={{ paddingTop: paddingTop }}>
      <>
        <label className='p'>{label}</label>
        <TextArea
          onBlur={props.handleBlur(field)}
          onChange={props.handleChange(field)}
          value={props.values[field]}
          className='select'
          placeholder={placeholder}
          autoSize={{ minRows: 8 }}
          maxLength={1000}
        />
        {props.touched[field] && props.errors[field] && (
          <small className='field-error'>{props.errors[field]}</small>
        )}
      </>
    </div>
  );
};
export const PSelect = ({ label, placeholder = 'Select Response', props, field, options, tags = false, nested = false, objectTitle = '', objectProperty = '' }: IPSelect) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const filteredOptions = options.filter((o: { title: string }) => !selectedItems.includes(o.title));

  if (props.values[field] === '' && tags === true) {
    props.values[field] = null;
  }
  return (
    <div className='AddLocationFormContainer flex'>
      <label className='p'>{label}</label>
      {
        tags ?
          <Select
            defaultActiveFirstOption={true}
            className='select'
            placeholder={placeholder}
            onBlur={props.handleBlur(field)}
            onChange={(val) => {
              let value = val.toString();
              (props.setFieldValue(field, value));
              setSelectedItems(val);
            }}
            value={props.values[field]?.split(',')}
            mode={'tags'}
            tokenSeparators={[',']}
            bordered={false}
          >
            {
              filteredOptions.map((opt, index) => <Select.Option style={{ textTransform: 'capitalize' }} key={index} value={opt.value}>{opt.title}</Select.Option>)
            }
          </Select> : <Select
            defaultActiveFirstOption={true}
            className='select'
            placeholder={placeholder}
            onBlur={props.handleBlur(field)}
            onChange={(val) => props.setFieldValue(field, val)}
            value={props.values[field]}
            bordered={false}
          >
            {
              options.map((opt, index) => <Select.Option style={{ textTransform: 'capitalize' }} key={index} value={opt.value}>{opt.title}</Select.Option>)
            }
          </Select>
      }
      {nested && props.touched[objectTitle] && props.touched[objectTitle][objectProperty] &&
        props.errors[objectTitle] && props.errors[objectTitle][objectProperty] &&
        (
          <small className='field-error'>{props.errors[objectTitle][objectProperty]}</small>
        )}
      {props.touched[field] && props.errors[field] && <small className='field-error'>{props.errors[field]}</small>}
    </div>
  );
};

export const PButton = ({
  props,
  buttonState,
  text,
}: {
  props: any;
  text: string;
  buttonState: {
    processing: boolean;
    setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  };
}) => {
  return (
    <div className='apartmentBtnWrap'>
      <FlexibleDiv className={
        !buttonState.processing
          ? 'apartmentBtnWrap'
          : 'apartmentBtnDisabled'
      }>
        <Button onClick={props.handleSubmit} className='apartmentBtnWrap'>
          {!buttonState.processing
            ? text
            : 'processing your request...'}
        </Button>
      </FlexibleDiv>
    </div>
  );
};

export const PCountrySelect = ({
  label,
  props,
  field,
  disabled,
}: IPCountrySelect) => {
  return (
    <>
      <label className='p'>{label}</label>
      <Select
        showSearch
        defaultActiveFirstOption={true}
        className='select'
        placeholder={'Select Country'}
        onBlur={props.handleBlur(field)}
        onChange={(val) => {
          props.setFieldValue(field, val);
          props.setFieldValue('state', '');
        }}
        value={props.values[field]}
        disabled={disabled}
      >
        {countries.map((item) => (
          <Select.Option value={item.country} key={item.country}>
            {item.country}
          </Select.Option>
        ))}
      </Select>
      {props.touched[field] && props.errors[field] && (
        <small className='field-error'>{props.errors[field]}</small>
      )}
    </>
  );
};

export const PStateSelect = ({
  label,
  props,
  field,
  states,
}: IPCountrySelect) => (
  <>
    <label className='p'>{label}</label>
    <Select
      showSearch
      defaultActiveFirstOption={true}
      className='select'
      placeholder={'Select Country'}
      onBlur={props.handleBlur(field)}
      onChange={(val) => props.setFieldValue(field, val)}
      value={props.values[field]}
    >
      {states &&
        states.map((item) => (
          <Select.Option value={item} key={item}>
            {item}
          </Select.Option>
        ))}
    </Select>
    {props.touched[field] && props.errors[field] && (
      <small className='field-error'>{props.errors[field]}</small>
    )}
  </>
);

export const FmmInput = ({ label, type = 'text', placeholder = 'Enter Response', props, value, field, edit = false }: IPInput) => {

  return (
    <ThemeProvider theme={FmmTheme}>
      <FmmFormStyles>
        <label>{label}</label>
        <input
          type={type}
          placeholder={placeholder}
          onBlur={props.handleBlur(field)}
          onChange={props.handleChange(field)}
          className={edit ? 'disableInput' : ''}
          disabled={edit ? true : false}
          value={props.values[field]}
          onFocus={() => {
            if (type === 'number' && value === 0) props.setFieldValue(field, '');
          }}
          id={label.split(' ').join()}
        />
        {props.touched[field] && props.errors[field] && <small className='field-error'>{props.errors[field]}</small>}
      </FmmFormStyles>
    </ThemeProvider>
  );
};


export const FmmSubmitButton = ({ props, buttonState, title = 'Continue to Add Service', buttonType }: {
  props: any,
  buttonState: {
    processing: boolean, setProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  }, title?: string, theme: ITheme, buttonType?: 'button' | 'submit' | 'reset'
}) => {
  return (
    <FmmButton
      text={!buttonState.processing ? title : 'processing your request...'}
      height='44px'
      border={!buttonState.processing ? '1px solid transparent' : `1px solid ${FmmTheme.colors.border}`}
      onClick={props.handleSubmit}
      color={!buttonState.processing ? FmmTheme.colors.text.white : FmmTheme.colors.border}
      backgroundColor={!buttonState.processing ? FmmTheme.colors.primary : FmmTheme.colors.text.white}
      buttonType={buttonType}
    />
  );
};

export const ServicesForm = ({ service, serviceFormDescription, sectionTitle, content }: { service: string, serviceFormDescription: string, sectionTitle: string | React.JSX.Element, content: React.JSX.Element }) => {
  return (
    <ThemeProvider theme={FmmTheme}>
      <FmmFormStyles>
        <div className='servicesFormContainer'>
          <div className='servicesForm'>
            <div className='servicesFormHeader'>
              <h4>{service}</h4>
              <p className='serviceFormDescription'>{serviceFormDescription}</p>
            </div>

            <div className='sectionTitle'>
              <h5 className="w-full">{sectionTitle}</h5>
            </div>
            {content}
          </div>
        </div>
      </FmmFormStyles>
    </ThemeProvider>
  );
};

export const FmmRadio = ({ label, options, field, props, optionType = 'default', buttonStyle = 'solid' }: { label: string, options: string[], field: string, optionType?: 'default' | 'button', buttonStyle?: 'outline' | 'solid', props?: any }) => {
  return (
    <ThemeProvider theme={FmmTheme}>
      <FmmFormStyles>
        <label htmlFor='FmmRadio'>{label}</label>
        <div className="fmmRadio">
          <Radio.Group buttonStyle={buttonStyle} optionType={optionType} onChange={(e) => props?.setFieldValue(field, e.target.value)} value={props?.values[field]}>
            {options.map((option, index) => <Radio key={index} value={option}>{option}</Radio>)}
          </Radio.Group>
        </div>
        {props?.touched[field ?? ''] && props?.errors[field ?? ''] && <small className='field-error'>{props?.errors[field ?? '']}</small>}
      </FmmFormStyles>
    </ThemeProvider>
  );
};

export const FmmPhoneNumberInput = ({ label, props, field }: { label: string, props: any, field: string }) => {
  const [value, setValue] = useState('+234');

  const handleChange = (val: string) => {
    setValue(val);
  };

  const prefixSelector = (
    <Select onChange={handleChange} defaultValue={value} style={{ width: 80 }}>
      <Select.Option value="+234"> <div> <Image src={'/nigeria_flag.png'} alt='Nigerian Flag' height="24" width="18" preview={false} /> &nbsp; Nigeria </div> </Select.Option>
      <Select.Option value="+233"> <div> <Image src={'/ghana_flag.png'} alt='Ghanaian Flag' height="24" width="18" preview={false} /> &nbsp; Ghana </div> </Select.Option>
      <Select.Option value="+254"> <div> <Image src={'/kenya_flag.png'} alt='Kenyan Flag' height="24" width="18" preview={false} /> &nbsp; Kenya </div> </Select.Option>
      <Select.Option value="+27"> <div> <Image src={'/south_africa_flag.png'} alt='South African Flag' height="24" width="18" preview={false} /> &nbsp; South Africa  </div> </Select.Option>
    </Select>
  );
  return (
    <ThemeProvider theme={FmmTheme}>
      <FmmFormStyles>
        <label htmlFor='FmmPhoneNumber'>{label}</label>
        <Input onChange={(e) => props?.setFieldValue(field, `${value}${e.target.value}`)} className='FmmPhoneNumber' prefix={value} addonBefore={prefixSelector} style={{ width: '100%' }} />
        {props?.touched[field ?? ''] && props?.errors[field ?? ''] && <small className='field-error'>{props?.errors[field ?? '']}</small>}
      </FmmFormStyles>
    </ThemeProvider>
  );
};
