import styled from 'styled-components';
import { FmmTheme } from '../theme';

export const FmmFormStyles = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: ${FmmTheme.textSize.md};
    /* line-height: ${FmmTheme.textLineHeight.md}; */
    color: ${FmmTheme.colors.text.label};
    font-weight: 500;
    letter-spacing: 0;
  }

  .field-error {
    font-size: 13px;
    color: ${FmmTheme.colors.text.primary};
  }

  input,
  .select,
  .selects,
  .selectd,
  .selectdd,
  .selectButton,
  .ant-picker {
    width: 100%;
    padding: ${FmmTheme.padding.input};
    height: 44px;
    border: 1px solid ${FmmTheme.colors.border1};
    border-radius: ${FmmTheme.borderRadius.info};
    outline-color: ${FmmTheme.colors.border1};
    font-size: 1rem;
    &::placeholder {
      color: ${FmmTheme.colors.text.body};
      font-weight: 500;
    }
    &:focus {
      outline: 0.5px solid ${FmmTheme.colors.border1};
    }
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-select-selector {
      border: none;
      width: 100%;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .TextAreaForm {
    width: 100%;

    .ant-input:hover {
      outline-color: ${FmmTheme.colors.border};
    }
    textarea {
      &::placeholder {
        color: #667085;
        font-weight: 500;
        font-size: 0.8rem;
      }
    }
  }

  /* This remove the blue background after autofill on Chrome */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #333;
  }

  .documentUpload {
    width: 100%;
    font-size: 1rem;
    ::placeholder {
      color: ${FmmTheme.colors.text.body};
      font-weight: 500;
      font-size: 1rem;
    }
    &:focus {
      outline: 0.5px solid ${FmmTheme.colors.border};
    }

    Button {
      padding: 1.25rem;
    }

    .icon {
      margin-right: 0.75rem;
      font-size: 1.25rem;
    }
  }

  .servicesFormContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 300px;

    @media (max-width: 1023px) {
      padding: 20px 200px;
    }
    @media (max-width: 738px) {
      padding: 20px 10px;
    }

    .servicesForm {
      min-width: 1000px;
      width: 100%;
      min-height: 525px;
      border-radius: 12px;
      border-top: 3px solid #ff6029;
      padding: 20px 0 0 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      background: linear-gradient(180deg, #fff0eb 0%, #ffffff 7.94%);
      box-shadow: 0px 8px 25px 3px rgba(77, 77, 77, 0.05);

      @media (max-width: 1023px) {
        min-width: 674px;
      }
      @media (max-width: 738px) {
        min-width: 327px;
      }
    }

    .servicesFormHeader {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: left;
      padding: 0 32px;
      gap: 8px;
      max-height: 96px;
      width: 100%;

      h4 {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #363636;
      }
    }

    .sectionTitle {
      width: 100%;
      padding: 16px 32px;
      display: flex;
      justify-content: space-between;
      align-items: left;
      min-height: 56px;
      border-block: 1px solid #f2f2f2;

      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #363636;
      }
    }

    .serviceFormDescription {
      width: 60%;
      font-weight: 400;
      line-height: 24px;
      font-size: 16px;
      color: #808080;

      @media (max-width: 738px) {
        width: 100%;
      }

      @media (max-width: 425px) {
        font-size: 12px;
      }
    }

    .formGrid {
      display: grid;
      padding: 12px 32px;
      grid-template-columns: 2fr 2fr;
      gap: 24px;

      @media (max-width: 800px) {
        grid-template-columns: 1fr;
      }
    }

    .fullGrid {
      display: grid;
      padding: 12px 32px;
      grid-template-columns: 1fr;
    }
  }

  .fmmRadio {
    .ant-radio-button-wrapper {
      span {
        color: #004646;
      }
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:hover {
      background-color: #8ac5c5;
      border-color: #c5c5c5;
    }

    .ant-radio-button-wrapper-checked:hover::before,
    .ant-radio-button-wrapper-checked::before {
      background-color: #c5c5c5;
    }
  }

  .FmmPhoneNumber {
    height: 44px;
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
      ):hover
      .ant-select-selector {
      border-color: transparent;
      box-shadow: none;
      -webkit-box-shadow: none;
    }

    .ant-select-selection-item {
      color: ${FmmTheme.colors.text.body};
    }

    .ant-select-selector {
      border-color: transparent;
      border: none;
      width: 100%;
      &:focus,
      &:hover {
        outline: none;
        box-shadow: none;
      }
    }

    .ant-input-prefix {
      color: #363636;
    }

    .ant-input-affix-wrapper {
      border: 1px solid #c5c5c5;
      outline-color: #c5c5c5;
      padding: 0 10px;

      .ant-input {
        border: none;
        outline: none;
      }
    }
  }
`;
